export const CONFIG = {
  LOCALSTORAGE_USER: "pushtokUser",
  ENVIRONMENT: "production",
  MEASUREMENT_ID: "G-PP62XPK6R0", //"G-7VK9B1V8CZ", //"UA-258991804-1",
  TRACKING_ID: "UA-259190236-1",
  BACKEND_API_URL:
    // "http://localhost:8443/v1/",
    //"https://18.144.174.18:7000/v1/",
    //"https://app.pushtok.com:7000/v1/",
    "https://stage.pushtok.com/v1/",
  PUBNUB_PUBLISH_KEY: "pub-c-2dfbfd67-ec83-4929-b426-52932378afe2", //"pub-c-3ef433fb-6ad5-4652-9327-f5220db37346",
  PUBNUB_SUBSCRIBE_KEY: "sub-c-c66b0f32-a454-11ec-acf5-86a1e6519840", //"sub-c-c4e32f5c-9165-11ec-8158-ea060f348a12",
  PUBNUB_SECRET_KEY: "sec-c-MmEzNjA3ZmEtMzhiYS00ZDE3LWFhYjQtZjgwYmY0NjhiZDNl", //"sec-c-YzhjMGNkNDEtZWFhNi00ZjRjLWFmM2MtMjRmNWJjNTk5OWJm"
  BOOST_REDIRECT_URL: "http://ec2-54-215-115-144.us-west-1.compute.amazonaws.com/boost", //"http://localhost:3000/boost",
};
